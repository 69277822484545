import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

// import Types from '../../common/modules/types';

const RecieverID = styled.code`
  font-size: 95%;
  text-transform: uppercase;
`;

const RecieverName = styled.span`

`;

export const columnName = {
  path: '_id',
  title: 'Receiver',
  render: record => (
    <Link to={`/recievers/${record._id}`}>
      <RecieverName>
        <RecieverID>{record._id}</RecieverID>
        &nbsp;
        /
        &nbsp;
        {record.name}
      </RecieverName>
    </Link>
  ),
};

// export const columnStore = {
//   path: 'store.name',
//   title: 'Store',
//   link: '/stores/:store._id',
// };

export const ADMIN = [
  columnName,
];
