// import React from 'react';
import moment from 'moment';

export const columnName = {
  path: 'name',
  width: '100%',
  link: '/routes/:_id',
};

export const columnCreatedAt = {
  path: 'createdAt',
  title: 'Created',
  align: 'right',
  render: record => moment(record.createdAt).format('lll'),
};

export const columnUpdatedAt = {
  path: 'updatedAt',
  title: 'Updated',
  align: 'right',
  render: record => moment(record.updatedAt).format('lll'),
};

export const ADMIN = [
  columnName,
  columnCreatedAt,
  columnUpdatedAt,
];
