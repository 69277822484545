import React, {
  // useMemo,
} from 'react';
import styled from 'styled-components';

import queryString from 'querystring';
import omit from 'lodash/omit';
import moment from 'moment';

import {
  Link,
  // useLocation,
  // useHistory,
} from 'react-router-dom';

import Checkbox from 'antd/lib/checkbox';

import Admin from 'hive-admin';

import Button from 'antd/lib/button';

const ColumnWrapper = styled.div`
  .ant-checkbox-wrapper {
    display: inline;
    /* margin-right: 20px; */
    vertical-align: middle;
    .ant-checkbox {
      transform: translateY(-1px);
    }
  }
  @media(min-width: 769px) {
    white-space: nowrap;
    .ant-checkbox-wrapper {
      /* margin-right: 10px; */
    }
  }
  a, span.label {
    vertical-align: middle;
    display: inline;
  }
`;

// function ColumnNameTitle() {
//   // const history = useHistory();
//   // const location = useLocation();
//   // const params = useMemo(
//   //   () => queryString.parse(location.search.slice(1)),
//   //   [location.search],
//   // );
//   return (
//     <ColumnWrapper>
//       {/* <Checkbox
//         checked={`${params.all}` !== 'false'}
//         onChange={event => history.replace(`${location.pathname}?${
//           queryString.stringify({
//             ...omit(params, 'all', 'list'),
//             all: event.target.checked,
//           })
//         }`)}
//       /> */}
//       <span className="label">
//         Started At
//       </span>
//     </ColumnWrapper>
//   );
// }

function ColumnMapCheckbox({ record, location, history, params }) {
  // const all = `${params.all}` !== 'false';
  const inList = params.list && params.list.includes(record._id);
  // const checked = all ? (inList ? false : true) : (inList ? true : false);
  const checked = inList;
  return (
    <ColumnWrapper>
      <Checkbox
        checked={checked}
        onChange={(event) => {
          let list;
          if (inList) {
            list = (params.list ? params.list.split(',') : []).filter(
              id => id !== record._id
            );
          } else {
            list = (params.list ? params.list.split(',') : []);
            list.push(record._id);
          }
          const newParams = { ...omit(params, 'list') };
          if (list && list.length) {
            newParams.list = list.join(',');
          }
          history.replace(`${location.pathname}?${
            queryString.stringify(newParams)
          }`);
        }}
      />
      {/* {moment(record.createdAt).format('lll')} */}
    </ColumnWrapper>
  );
}

export const columnMapList = {
  path: 'map',
  title: null,
  render: (record, index, props) => (
    <ColumnMapCheckbox
      record={record}
      location={props.location}
      history={props.history}
      params={props.searchParams}
    />
  ),
};

export const columnCreatedAt = {
  path: 'createdAt',
  title: 'Started At',
  render: (record, index, props) => (
    moment(record.createdAt).format('lll')
  ),
};

export const columnCapturesCount = {
  path: 'capturesCount',
  title: 'Scans',
  align: 'right',
  render: record => record.capturesCount,
};

export const columnRoute = {
  path: 'route',
  title: 'Route',
  width: '100%',
  render: record => (
    <Link to={`/routes/${record.route}`}>
      {record.routeName}
    </Link>
  ),
};

export const columnReciever = {
  path: 'reciever',
  title: 'Receiver',
  render: record => (
    <Link to={`/recievers/${record.reciever}`}>
      {record.recieverName}
    </Link>
  ),
};

const actionCompile = Admin.compileFromLibrary(['ActionWithRequest', {
  name: 'compile',
  title: 'Compile',
  ghost: true,
  type: 'danger',
  icon: null,
  size: 'small',
  getRequestConfig: props => ({
    url: `sessions/${props.actionParams.id}/compile`,
    method: 'post',
  }),
  renderAction: (actionProps, instance) => {
    // eslint-disable-next-line max-len
    const { props: { onClick, disabled } } = instance.renderAction(actionProps);
    return (
      <Button
        style={{ padding: '0px' }}
        type="link"
        onClick={onClick}
        disabled={disabled}
      >
        {actionProps.title}
      </Button>
    );
  },
  handleSuccess: (data, props) => props.reload(),
}]);

export const columnActions = {
  path: 'actions',
  title: 'Map Overlay',
  render: (record, index, props) => {
    let label = null;
    const { map: { status } } = record;
    if (status === 'DONE') {
      label = 'Ready';
    } else if (status === 'COMPILING') {
      label = 'Compiling';
    } else if (status === 'QUEUED') {
      label = 'Queued';
    } else if (status === 'FAILED') {
      // label = 'Try Again';
    }
    if (label) {
      return label;
    }
    return actionCompile.render({
      ...props,
      label,
      actionParams: {
        id: record._id,
      },
    });
  },
};

// eslint-disable-next-line import/prefer-default-export
export const ADMIN = [
  columnMapList,
  columnCreatedAt,
  columnCapturesCount,
  columnRoute,
  columnReciever,
  columnActions,
];
