import Types from '../../common/modules/types';

export const columnName = {
  path: 'name',
  width: '100%',
  link: '/users/:_id',
};

export const columnEmail = 'email';

export const columnRole = {
  path: 'role',
  title: 'Role',
  render: record => Types.USER_ROLE_LABELS_MAP[record.role],
};

export const columnActive = {
  path: 'active',
  title: 'Status',
  render: record => (record.active ? 'Active' : 'Inactive'),
};

export const ADMIN = [
  columnName,
  columnEmail,
  columnRole,
  columnActive,
];
