import Admin from 'hive-admin';

Admin.addToLibrary(
  'redirect.viewerHasNoRole',
  (destination = '/', roles = []) => ({ isAuthorized, viewer, location }) => (
    !isAuthorized || !viewer
    ? false
    : !roles.includes(viewer.role)
    ? destination
    : false
  ),
);

Admin.addToLibrary(
  'redirect.viewerHasRole',
  (destination = '/', roles = []) => ({ isAuthorized, viewer, location }) => (
    !isAuthorized || !viewer
    ? false
    : roles.includes(viewer.role)
    ? destination
    : false
  ),
);
