import getKey from 'lodash/get';

import Admin from 'hive-admin';

import { ADMIN as FILTERS_ADMIN } from './filters';
import { ADMIN as COLUMNS_ADMIN } from './columns';
import { ADMIN as FIELDS_ADMIN } from './fields';

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Receivers',
    icon: 'tablet',
    path: '/recievers',
    redirect: [['redirect.unauthorized']],
    headerTitleKey: '_id',
    archiveConfig: {
      path: '/recievers',
      title: 'Receivers',
      label: 'Receivers',
      icon: 'tablet',
      createButtonPath: '/recievers/create',
      getCreateButtonPath: props => (
        props.viewer && props.viewer.role === 'ADMIN'
        ? props.createButtonPath
        : null
      ),
      titleColumnPath: '_id',
    },
    singleConfig: {
      hidden: true,
      alias: '/recievers',
      headerTitle: 'Create Receiver',
      backButtonPaths: ['/recievers'],
    },
    editConfig: {
      actions: [
        ['ActionSaveIfIsAdmin'],
        ['ActionDeleteIfIsAdmin'],
        ...[
          ['authorization-enable', 'Enable Authorization', true],
          ['authorization-disable', 'Disable Authorization', false],
        ].map(([name, title, value]) => ['ActionWithRequest', {
          name,
          title,
          skip: [
            props => !props.viewer || props.viewer.role !== 'ADMIN',
            props => !!getKey(props.data, 'authorizing') === value,
          ],
          getRequestConfig: props => ({
            url: `recievers/${props.data._id}/authorization`,
            method: 'POST',
            data: { authorizing: value },
          }),
          handleSuccess: (data, props) => props.reload(),
        }]),
      ],
    },
    filters: FILTERS_ADMIN,
    columns: COLUMNS_ADMIN,
    fields: FIELDS_ADMIN,
  }])
).pages;
