import './helpers/fixInfiniteScroller';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components/macro';

import styled from 'styled-components';

import Admin from 'hive-admin';

import { Link } from 'react-router-dom';

import './helpers/library';

import 'hive-admin/src/components/FieldGoogleAddress';
import 'hive-admin/src/components/FieldGooglePolygon';

import './antd.less';
import './admin.less';

import './components/PageArchiveTable.less';

import './components/ActionDeleteIfIsAdmin';
import './components/ActionSaveIfIsAdmin';
import './components/FieldColorPicker';
import './components/FilterActionLink';

// import DashboardPages from './routes/dashboard';
import SessionsPages from './routes/sessions';
import UserPages from './routes/users';
import RoutesPages from './routes/routes';
import RecieversPages from './routes/recievers';
// import SettingsPage from './routes/settings';

import theme from './theme';

// import Inter from './routes/dashboard/customers/components/Inter';

import { renderLogo, renderSidebarLogo } from './components/Logo';

import Types from './common/modules/types';

import interregLogoSrc from './common/assets/interreg-ipa.png';

const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_PATH,
  REACT_APP_PUBLIC_URL = '',
} = process.env;

const { SERVER_URL } = Types;

const base = window.ADMIN_BASE = `${REACT_APP_PUBLIC_URL}/`;
const restBase = REACT_APP_NODE_ENV !== 'production'
? `${REACT_APP_API_PATH}`
: `${SERVER_URL}${REACT_APP_API_PATH}`;
const restBaseRoot = `${SERVER_URL}${REACT_APP_API_PATH}`;

const InterregWrapper = styled.div`
  padding: 5px;
  margin-top: 50px;
`;

const InterregLogo = styled.img`
  width: 80%;
`;

const InterregInfo = styled.p`
  margin-top: 30px;
  text-align: left;
  font-size: 70%;
`;

const admin = Admin.create({
  base,
  restBase,
  restBaseRoot,
  titlePrefix: 'Flood&Fire | ',
  sidebarProps: { renderLogo: renderSidebarLogo },
  passwordSetSuccessRedirectPath: '/login',
  accountActivationSuccessRedirectPath: '/login',
  viewerUrl: '/users/me',
  validateViewer: async (viewer, props) => viewer,
  structure: [
    ['Page', {
      path: '/',
      exact: true,
      hidden: true,
      redirect: [
        ['redirect.unauthorized'],
        ['redirect.always', '/sessions'],
      ],
    }],
    ['PageLogin', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized', '/']],
      actions: [
        ['ActionLogin'],
        ['FieldReact', {
          label: null,
          // eslint-disable-next-line arrow-body-style
          renderContent: (props) => {
            return (
              <InterregWrapper>
                <InterregLogo
                  src={interregLogoSrc}
                />
                <InterregInfo>
                  The project is co-financed by ERDF and
                  IPA Il funds of the European Union.
                </InterregInfo>
              </InterregWrapper>
            );
          },
        }],
      ],
      renderAfterForm: props => (
        <>
          <p className="after-form" style={{ marginBottom: '0.3em' }}>
            Lost your password? Click
            {' '}
            <Link to={props.passwordResetPath}>
              here
            </Link>
            {' '}
            to set a new one.
          </p>
        </>
      ),
    }],
    // ...DashboardPages,
    ['PagePasswordReset', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized']],
    }],
    ['PagePasswordSet', { renderBeforeForm: renderLogo }],
    ['PageAccountActivation', {
      renderBeforeForm: renderLogo,
      accountActivationSuccessRedirectPath: '/',
    }],
    ...SessionsPages,
    ...RoutesPages,
    ...RecieversPages,
    ...UserPages,
    // SettingsPage,
    ['Page404', {
      redirect: [
        ['redirect.unauthorized'],
      ],
    }],
  ],
});

ReactDOM.render(
  (
    <ThemeProvider theme={{ ...theme }}>
      {/* <Inter weights={[500, 600, 700, 900]} /> */}
      {admin}
    </ThemeProvider>
  ),
  document.getElementById('root'),
);
