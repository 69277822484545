// import FieldUploadRefs from 'hive-admin/src/components/FieldUploadRefs';

import Types from '../../common/modules/types';

import tests from '../../helpers/tests';

const skipPassword = () => false;

const {
  viewerIsNotAdmin,
  isNotCreateActivity,
} = tests;

// export const fieldAvatar = ['FieldUploadRefs', {
//   name: 'avatar',
//   label: 'Avatar',
//   accept: 'image/png,image/jpg,image/jpeg',
//   transformations: [
//     // TODO check pictures max width
//     ['GM', { command: 'compress', maxWidth: 700 }],
//   ],
//   getUploadArguments: (props) => ({
//     ...FieldUploadRefs.config.getUploadArguments(props),
//     isNotDroneCapture: true,
//     meta: { isDroneCapture: false },
//   }),
//   // skip: [userIsNotCustomer],
// }];

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [['validation.isRequired'], ['validation.isName']],
}];

export const fieldEmail = ['FieldText', {
  name: 'email',
  label: null,
  prefix: 'Email:',
  autoComplete: 'new-email',
  rules: [['validation.isRequired'], ['validation.isEmail']],
}];

export const fieldRole = ['FieldSelect', {
  name: 'role',
  label: null,
  prefix: 'Role:',
  initialValue: 'ADMIN',
  rules: [['validation.isRequired']],
  choices: Types.USER_ROLE_LIST.map(({ id, label }) => ({
    label,
    value: id,
  })),
  disabled: [isNotCreateActivity],
  virtual: [isNotCreateActivity],
  hidden: [
    viewerIsNotAdmin,
  ],
}];

export const fieldPasswordTitle = ['FieldTitle', {
  title: 'Password',
  skip: [skipPassword],
  // style: { marginTop: '10px' },
}];

export const fieldPassword = ['FieldText', {
  name: 'password',
  label: null,
  prefix: 'Password:',
  type: 'password',
  autoComplete: 'new-password',
  rules: [
    ['validation.isPassword'],
  ],
  skip: [skipPassword],
  col: { xs: 24, md: 12 },
}];

export const fieldConfirmPassword = ['FieldText', {
  name: 'confirmPassword',
  label: null,
  prefix: 'Confirm:',
  type: 'password',
  rules: [
    ['validation.isPasswordSame'],
  ],
  skip: [skipPassword],
  col: { xs: 24, md: 12 },
  virtual: true,
}];

export const ADMIN_ALL = [
  // fieldAvatar,
  fieldName,
  fieldEmail,
  fieldRole,
  fieldPasswordTitle,
  fieldPassword,
  fieldConfirmPassword,
];
