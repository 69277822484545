import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';

// import {
//   PlusOutlined,
//   QrcodeOutlined,
//   LoadingOutlined,
// } from '@ant-design/icons';

import WaypointsMap, { ElevationGraph } from '@thehivers/cli-drone-mapping-map';

// eslint-disable-next-line max-len
const webkitMask = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC';

const WaypointsEditorWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WaypointsMapWrapper = styled.div`
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 450px;
  border-radius: ${({ theme }) => theme.less.borderRadius};
  mask-image: url("${webkitMask}");
`;

const ElevationGraphWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  border-radius: ${({ theme }) => theme.less.borderRadius};
  mask-image: url("${webkitMask}");
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
`;

const Stat = styled.span`
  color: ${({ danger, theme }) => (danger ? theme.less.primaryColor : 'black')};
  opacity: ${({ danger }) => (danger ? 1 : 0.5)};
`;

const TERRAIN_VALUES = {
  NONE: {
    useTerrainElevation: false,
    autoFollowTerrain: false,
  },
  ELEVATION: {
    useTerrainElevation: true,
    autoFollowTerrain: false,
  },
  FOLLOW: {
    useTerrainElevation: true,
    autoFollowTerrain: true,
  },
};

export default function WaypointsEditor({ value, onChange, form }) {
  const theme = useTheme();
  const [mapData, setMapData] = useState({});

  const [
    speed,
    altitude,
    addedHomeAltitude,
    elevationSimplificationFactor,
    elevationPrecision,
    forwardOverlap,
    sideOverlap,
  ] = [
    form.getFieldValue('speed'),
    form.getFieldValue('altitude'),
    form.getFieldValue('editorParams.addedHomeAltitude'),
    form.getFieldValue('editorParams.elevationSimplificationFactor'),
    form.getFieldValue('editorParams.elevationPrecision'),
    form.getFieldValue('editorParams.forwardOverlap'),
    form.getFieldValue('editorParams.sideOverlap'),
  ].map(inputValue => (
    Number.isFinite(inputValue) ? inputValue : undefined
  ));

  const [
    drone,
  ] = [
    form.getFieldValue('editorParams.droneModel'),
  ].map(inputValue => (
    typeof inputValue == 'undefined' ? undefined : inputValue
  ));

  const {
    useTerrainElevation,
    autoFollowTerrain,
  } = (
    TERRAIN_VALUES[form.getFieldValue('editorParams.terrain')]
    || TERRAIN_VALUES.FOLLOW
  );

  console.log('value waypoints:', value);

  return (
    <WaypointsEditorWrapper>
      <WaypointsMapWrapper>
        <WaypointsMap
          value={value}
          onChange={(inputValue) => {
            console.log('onChange waypoints:', inputValue);
            onChange(inputValue);
          }}
          onUpdate={(event) => {
            setMapData(event);
            const { captureDistance } = event || {};
            if (Number.isFinite(captureDistance) && captureDistance > 0) {
              form.setFieldsValue({ captureDistance });
            }
          }}
          missionSpeed={speed}
          missionAltitude={altitude}
          addedHomeAltitude={addedHomeAltitude}
          elevationSimplificationFactor={elevationSimplificationFactor}
          elevationPrecision={elevationPrecision}
          useTerrainElevation={useTerrainElevation}
          autoFollowTerrain={autoFollowTerrain}
          drone={drone}
          // eslint-disable-next-line max-len
          accessToken="pk.eyJ1IjoidGhlaGl2ZS1ycyIsImEiOiJjbDRoOGNsc3IwOHhjM2R1bDdrMG96OWk0In0._eQlU8T2UWBQSoWZEbeTlg"
          color={theme.less.primaryColor}
          {...Object.assign(
            {},
            Number.isFinite(forwardOverlap) ? { forwardOverlap } : {},
            Number.isFinite(sideOverlap) ? { sideOverlap } : {},
          )}
        />
      </WaypointsMapWrapper>
      { mapData?.route ? (
        <ElevationGraphWrapper>
          <ElevationGraph
            terrain={mapData.terrain}
            route={mapData.route}
            bgColor={theme.less.primaryColor}
            lineColor={theme.less.primaryColor}
            textColor={theme.less.textColor}
            aspectRatio={5}
          />
        </ElevationGraphWrapper>
      ) : null }
      <Stats>
        <Stat danger={
          mapData?.minRelativeElevation < -200
          || mapData?.maxRelativeElevation > 500
          }
        >
          {`
            Min/Max altitude: ${mapData?.minRelativeElevation
              ? `${mapData?.minRelativeElevation}m
                / ${mapData?.maxRelativeElevation}m`
              : 'N/A'}
          `}
        </Stat>
        <Stat danger={mapData?.estimates?.battery > 90}>
          {`
            Estimated battery required: ${mapData?.estimates?.battery
            ? (
              `${mapData?.estimates?.battery}%`
            ) : 'N/A'}
          `}
        </Stat>
        <Stat>
          {`
            Estimated flight duration: ${mapData?.estimates?.time
              ? moment.utc(mapData?.estimates?.time * 1000).format('HH:mm:ss')
              : 'N/A'
            }
          `}
        </Stat>
      </Stats>
    </WaypointsEditorWrapper>
  );
}
