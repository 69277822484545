import Admin from 'hive-admin';

import { ADMIN as FILTERS_ADMIN } from './filters';
import { ADMIN as COLUMNS_ADMIN } from './columns';
import { ADMIN as FIELDS_ADMIN } from './fields';

import tests from '../../helpers/tests';

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Routes',
    icon: 'rollback',
    path: '/routes',
    redirect: [['redirect.unauthorized']],
    headerTitleKey: 'name',
    filters: FILTERS_ADMIN,
    columns: COLUMNS_ADMIN,
    fields: FIELDS_ADMIN,
    archiveConfig: {
      path: '/routes',
      title: 'Routes',
      label: 'Routes',
      icon: 'rollback',
      createButtonPath: '/routes/create',
      getCreateButtonPath: props => (
          props.viewer && props.viewer.role === 'ADMIN'
        ? props.createButtonPath
        : null
      ),
    },
    singleConfig: {
      hidden: true,
      alias: '/routes',
      headerTitle: 'Create Route',
      backButtonPaths: ['/routes'],
      duplicateLoadUrl: '/routes/:duplicate',
    },
    singleEditConfig: {
      actions: [
        ['ActionSave'],
        ['Action', {
          name: 'duplicate',
          title: 'Duplicate',
          ghost: true,
          onClick: (props) => props.history.push(
            `/routes/create?duplicate=${
              props.data ? props.data._id : null
            }`
          ),
        }],
        ['ActionDelete'],
      ],
    },
    skip: [tests.viewerIsNotAdmin],
  }])
).pages;
