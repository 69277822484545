import Admin from 'hive-admin';
import OriginalActionDelete from 'hive-admin/src/components/ActionDelete';

export default class ActionDeleteIfIsAdmin extends OriginalActionDelete {
  static config = {
    ...OriginalActionDelete.config,
    skip: [
      props => !props.viewer || props.viewer.role !== 'ADMIN',
    ],
  }
}

Admin.addToLibrary(
  'ActionDeleteIfIsAdmin',
  config => ActionDeleteIfIsAdmin.create(config),
);
