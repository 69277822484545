import Admin from 'hive-admin';

import { ADMIN as FILTERS_ADMIN } from './filters';
import { ADMIN as COLUMNS_ADMIN } from './columns';
import { ADMIN as FIELDS_ADMIN } from './fields';

import tests from '../../helpers/tests';

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Sessions',
    icon: 'rocket',
    path: '/sessions',
    redirect: [['redirect.unauthorized']],
    headerTitleKey: 'name',
    filters: FILTERS_ADMIN,
    columns: COLUMNS_ADMIN,
    fields: FIELDS_ADMIN,
    archiveConfig: {
      path: '/sessions',
      title: 'Sessions',
      label: 'Sessions',
      icon: 'rocket',
      createButtonPath: null,
      createButtonSupported: false,
      // titleColumntPath: 'routeName',
      // subtitleColumnPath: 'name',
      // filters: FILTERS_ADMIN,
      // columns: COLUMNS_ADMIN,
      // skip: [tests.viewerIsNotAdmin],
      // hidden: true,
      // getCreateButtonPath: props => (
      //     props.viewer && props.viewer.role === 'ADMIN'
      //   ? props.createButtonPath
      //   : null
      // ),
    },
    singleConfig: {
      hidden: true,
      alias: '/sessions',
      headerTitle: 'Create Session',
      backButtonPaths: ['/sessions'],
    },
    singleCreateConfig: {
      skip: [() => true],
    },
    singleEditConfig: {
      actions: [
        ['ActionDelete'],
      ],
    },
    skip: [tests.viewerIsNotAdmin],
  }])
).pages;
