import isUndefined from 'lodash/isUndefined';

import COUNTRIES from '../assets/countries.json';

const Types = {};

Types.SERVER_URL = `${
  process.env.SERVER_PROTOCOL || process.env.REACT_APP_SERVER_PROTOCOL
}://${
  process.env.SERVER_HOST || process.env.REACT_APP_SERVER_HOST
}${
  (
    process.env.SERVER_PROTOCOL || process.env.REACT_APP_SERVER_PROTOCOL
  ) !== 'https'
  ? `:${process.env.HTTP_PORT || process.env.REACT_APP_HTTP_PORT}`
  : ''
}`;

Types.HOME_URL = process.env.HOME_URL || process.env.REACT_APP_HOME_URL;

Types.COUNTRIES = COUNTRIES;

Types.COUNTRIES_MAP = COUNTRIES.reduce(
  (agr, country) => { agr[country.iso3a2] = country; return agr; },
  {},
);

Types.COUNTRIES_DIAL_CODES = Types.COUNTRIES.reduce(
  (agr, country) => {
    country.dialCodes.forEach((dialCode, i) => {
      agr.push({
        value: dialCode,
        label: `+${dialCode} (${country.name})${i > 0 ? ` ${i}` : ''}`,
      });
    });
    return agr;
  },
  [],
);

Types.COUNTRY = 'rs';
Types.CURRENCY = 'RSD';

Types.COUNTRY_NAME = Types.COUNTRIES_MAP[Types.COUNTRY].name;

Types.TIMEZONE = 'Belgrade/Serbia';

Types.decimalize = (
  number,
  decimalSeparator = '.',
  rounding = 2,
  thousandSeparator,
) => {
  if (isUndefined(thousandSeparator)) {
    thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  }
  return number.toFixed(rounding)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i, values) => (
    (values.length === 2 && i === 1) || i === 0
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
};

Types.decimalizeInt = (number, decimalSeparator) => Types.decimalize(
  number,
  decimalSeparator,
  0,
);

Types.getRoundedAmount = (amount, rounding = 2) => (
  Math.ceil(amount * (10 ** rounding)) / (10 ** rounding)
);

Types.createConstant = valuesArray => valuesArray.reduce(
  (agr, value) => {
    agr[value] = value;
    return agr;
  },
  {},
);

Types.createValues = (
  name,
  values = [],
  {
    keyId = 'id',
    getId = value => value[keyId],
    keyLabel = 'label',
    getLabel = value => value[keyLabel],
  } = {},
) => values.reduce(
  (agr, value) => {
    const id = getId(value);
    const label = getLabel(value);
    agr[name] = agr[name] || [];
    agr[name].push(id);
    const nameList = `${name}_LIST`;
    agr[nameList] = agr[nameList] || [];
    agr[nameList].push(value);
    const nameMap = `${name}_MAP`;
    agr[nameMap] = agr[nameMap] || {};
    agr[nameMap][id] = value;
    const nameLabels = `${name}_LABELS`;
    agr[nameLabels] = agr[nameLabels] || [];
    agr[nameLabels].push(label);
    const nameLabelsMap = `${name}_LABELS_MAP`;
    agr[nameLabelsMap] = agr[nameLabelsMap] || {};
    agr[nameLabelsMap][id] = label;
    const nameConstants = `${name}_CONST`;
    agr[nameConstants] = agr[nameConstants] || {};
    agr[nameConstants][id] = id;
    return agr;
  },
  {},
);

Types.CONSTANTS = {};

Object.assign(
  Types,
  Types.createValues('USER_ROLE', [{
    id: 'ADMIN',
    label: 'Admin',
  }]),
  Types.createValues('ACCESS_TOKEN_TYPE', [{
    id: 'User',
    label: 'User',
  }, {
    id: 'Reciever',
    label: 'Reciever',
  }]),
);

Types.MODELS = [
  // 'Inspire 1',
  // 'Inspire 1 Pro',
  // 'Inspire 1 RAW',
  // 'Inspire 2',
  // 'Phantom 3 Professional',
  // 'Phantom 3 Advanced',
  // 'Phantom 3 Standard',
  // 'Phantom 3 4K',
  // 'Phantom 4',
  // 'Phantom 4 Pro',
  // 'Phantom4 Pro V2',
  // 'P4 Multispectral',
  // 'Mavic Air 2',
  // 'DJI Mini SE',
  // 'DJI Mini 2',
  // 'Phantom 4 RTK',
  // 'Matrice 100',
  // 'Matrice 200',
  // 'Phantom 4 Advanced',
  // 'Matrice 600',
  // 'Matrice 600 Pro',
  // 'A3',
  'Mavic Pro',
  'Spark',
  // 'Matrice 210',
  // 'Matrice 210 RTK',
  // 'Matrice 200 V2',
  // 'Matrice 210 V2',
  // 'Matrice 210 RTK V2',
  // 'Matrice 300 RTK',
  // 'Mavic Air',
  // 'Mavic 2 Pro',
  // 'Mavic 2 Zoom',
  // 'Mavic 2',
  // 'Mavic 2 Enterprise',
  'Mavic 2 Enterprise Dual',
  // 'Mavic Mini',
  // 'DJI Air 2S',
  // 'Only RemoteController',
  // 'N3',
];

Object.assign(Types.CONSTANTS, {

});

export default Types;
