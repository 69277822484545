// const isNotCreateActivity = ['condition.check', {
//   path1: 'activity',
//   op: '!==',
//   value2: 'create',
// }];

export const fieldName = ['FieldText', {
  name: 'name',
  label: 'Name',
  placeholder: 'Receiver Name',
  rules: [
    ['validation.isRequired'],
  ],
}];

export const ADMIN = [
  fieldName,
];
