import React, {
  useRef,
  useMemo,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Query from 'hive-admin/src/components/Query';

import { CapturesMap } from '@thehivers/cli-drone-mapping-map';

import getFilterWithCol from '../../helpers/getFilterWithCol';

const getValueForFieldDefault = value => (
  value && value.length
  ? value
  : undefined
);

const filterDatesConfigGetThis = (now, id, span) => (
    id === 'from'
  ? now.startOf(span)
  : now.endOf(span)
);

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { OR: [
      { routeName: { REGEX: value, OPTIONS: 'i' } },
      { recieverName: { REGEX: value, OPTIONS: 'i' } },
    ] },
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search by route name',
  }],
}];

export const filterSort = ['FilterField', {
  id: 'sort',
  label: null,
  section: 'top',
  VALUES_MAP: {
    first: { createdAt: -1 },
    last: { createdAt: 1 },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('sort', { ...filter.VALUES_MAP[value] })
    : builder.add('sort', { ...filter.VALUES_MAP.first })
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'sort',
    placeholder: 'Sort',
    defaultValue: 'first',
    initialValue: 'first',
    prepareValueForInput: value => (value ? value : 'first'),
    choices: [{
      label: 'Latest First',
      value: 'first',
    }, {
      label: 'Oldest First',
      value: 'last',
    }],
  }],
}];

export const [filterFrom, filterTo] = [
  ['from', 'Started after', 'GTE'],
  ['to', 'Started before', 'LTE'],
].map(([id, placeholder, operator]) => [
  'FilterField', {
    id,
    label: null,
    section: 'top',
    buildQuery: (value, builder, _1, _2, { pageProps }) => {
      if (value && pageProps) {
        builder.add(
          'where',
          { createdAt: { [operator]: value } },
        );
      }
    },
    getValueForField: value => (value ? moment(value) : undefined),
    getValueForQuery: value => (
      value
      ? filterDatesConfigGetThis(moment(value), id, 'day').toJSON()
      : undefined
    ),
    field: ['FieldDatePicker', {
      name: id,
      placeholder,
      format: 'll',
      showToday: false,
    }],
  },
]);

export const filterRoute = ['FilterField', {
  id: 'route',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { route: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'routes',
    url: '/routes',
    placeholder: 'Routes',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterReciever = ['FilterField', {
  id: 'reciever',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { reciever: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'reciever',
    url: '/recievers',
    placeholder: 'Receivers',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

// const filterMode = ['FilterField', {
//   id: 'mode',
//   label: null,
//   section: 'top',
//   propsFromPage: props => ({}),
//   buildQuery: () => {},
//   getValueForField: getValueForFieldDefault,
//   field: ['FieldRadio', {
//     name: 'mode',
//     placeholder: 'Sort',
//     defaultValue: 'first',
//     initialValue: 'first',
//     prepareValueForInput: value => (value ? value : 'visual'),
//     block: true,
//     choices: [{
//       label: 'Visual',
//       value: 'visual',
//     }, {
//       label: 'Thermal',
//       value: 'thermal',
//     }],
//   }],
// }];

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
  border-radius: ${({ theme }) => theme.less.borderRadius};
  overflow: hidden;
  margin-bottom: 20px;
`;

export const filterMap = ['Filter', {
  id: 'map',
  label: null,
  section: 'top',
  buildQuery: () => {},
  propsFromPage: ({
    history,
    searchParams,
    loading,
    reload,
    client,
    queryBuilder,
  }) => ({ history, searchParams, loading, reload, client, queryBuilder }),
  FilterClass: (props) => {
    const dataSourceDefaults = useMemo(
      () => [],
      [],
    );
    const dataSourceRef = useRef(dataSourceDefaults);
    return (
      <Query
        url={`sessions/captures?loading=${
          !!props.loading
        }&query=${
          props.queryBuilder.compile()
        }`}
        client={props.client}
        extractData={response => (
            (response && response.data)
          ? response.data
          : null
        )}
      >
        {({ loading, data }) => {
          if (!loading) {
            // TODO Maybe allow single capture
            if (data && data.captures && data.captures.length > 1) {
              dataSourceRef.current = data;
            } else {
              dataSourceRef.current = dataSourceDefaults;
            }
          }
          // console.log(
          //   'sessions map value:',
          //   dataSourceRef.current,
          // );
          // console.log('sessions map thermal:', !!isThermal);
          // return (
          //   <pre>
          //     <code>
          //       {JSON.stringify(dataSourceRef.current, null, '  ')}
          //     </code>
          //   </pre>
          // );
          return (
            <MapWrapper>
              <CapturesMap
                value={dataSourceRef.current}
                // thermal={!!isThermal}
                // eslint-disable-next-line max-len
                accessToken="pk.eyJ1IjoidGhlaGl2ZS1ycyIsImEiOiJjbDRoOGNsc3IwOHhjM2R1bDdrMG96OWk0In0._eQlU8T2UWBQSoWZEbeTlg"
              />
            </MapWrapper>
          );
        }}
      </Query>
    );
  },
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const filterSessionsChecklist = ['FilterHidden', {
  id: 'list',
  section: 'bottom',
  build: (builder, filter, value, params) => {
    const ids = value && value.length ? value.split(',') : [];
    // const all = `${params.all}` !== 'false';
    const all = false;
    builder.add('custom', { sessionsAll: all });
    if (all) {
      builder.add('custom', { sessionsNin: ids });
    } else {
      builder.add('custom', { sessionsIn: ids });
    }
  },
}];

export const ADMIN = [
  ...[
    [filterSearch, 12],
    [filterSort, 12],
    [filterFrom, 12],
    [filterTo, 12],
    [filterRoute, 12],
    [filterReciever, 12],
    // [filterMode, 24],
    [filterMap, 24],
  ].map(args => getFilterWithCol(...args)),
  filterPagination,
  filterSessionsChecklist,
];
