// import React from 'react';

// import WaypointsEditor from '../../components/WaypointsEditor';

// import getFilterWithCol from '../../helpers/getFilterWithCol';

// import Types from '../../common/modules/types';

// export const fieldName = ['FieldText', {
//   name: 'name',
//   label: null,
//   prefix: 'Name:',
//   rules: [
//     ['validation.isRequired'],
//   ],
// }];

// export const fieldDroneModel = ['FieldSelect', {
//   name: 'editorParams.droneModel',
//   label: null,
//   prefix: 'Done Model:',
//   choices: Types.MODELS.map(label => ({
//     label,
//     value: label,
//   })),
//   initialValue: 'MAVIC_2_ENTERPRISE',
// }];

// export const fieldWaypoints = ['FieldReact', {
//   name: 'waypoints',
//   label: null,
//   virtual: [() => false],
//   renderContent: props => (
//     <WaypointsEditor {...props} />
//   ),
// }];

// export const fieldTerrain = ['FieldRadio', {
//   name: 'editorParams.terrain',
//   label: null,
//   block: true,
//   choices: [
//     { label: 'Manual', value: 'NONE' },
//     { label: 'With Elevation', value: 'ELEVATION' },
//     { label: 'Auto Follow Terrain', value: 'FOLLOW' },
//   ],
//   initialValue: 'FOLLOW',
// }];

// export const fieldElevationPrecision = ['FieldText', {
//   name: 'editorParams.elevationPrecision',
//   // label: 'Elevation Precision',
//   label: null,
//   prefix: 'Precision:',
//   type: 'number',
//   addonAfter: 'm',
//   initialValue: 10,
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 5, max: 50 }],
//   ],
// }];

// export const fieldElevationSimplificationFactor = ['FieldText', {
//   name: 'editorParams.elevationSimplificationFactor',
//   // label: 'Elevation Simplification Factor',
//   label: null,
//   prefix: 'Simplification:',
//   type: 'number',
//   initialValue: 500,
//   addonAfter: 'm',
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 5, max: 2000 }],
//   ],
// }];

// export const fieldAltitude = ['FieldText', {
//   name: 'altitude',
//   label: null,
//   prefix: 'Altitude:',
//   addonAfter: 'm',
//   type: 'number',
//   initialValue: 100,
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 5, max: 480 }],
//   ],
// }];

// export const fieldAddedHomeAltitude = ['FieldText', {
//   name: 'editorParams.addedHomeAltitude',
//   label: null,
//   prefix: 'Home Altitude:',
//   type: 'number',
//   initialValue: 0,
//   addonAfter: 'm',
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 0 }],
//   ],
// }];

// export const fieldSpeed = ['FieldText', {
//   name: 'speed',
//   label: null,
//   prefix: 'Speed:',
//   addonAfter: 'm/s',
//   type: 'number',
//   initialValue: 7,
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 2, max: 15 }],
//   ],
// }];

// export const fieldPitch = ['FieldText', {
//   name: 'pitch',
//   label: null,
//   prefix: 'Pitch:',
//   addonAfter: 'degrees',
//   type: 'number',
//   initialValue: 90,
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 0, max: 90 }],
//   ],
// }];

// export const fieldCaptureDistance = ['FieldText', {
//   name: 'captureDistance',
//   label: null,
//   prefix: 'Capture every:',
//   addonAfter: 'm',
//   type: 'number',
//   initialValue: 100,
//   rules: [
//     ['validation.isRequired'],
//     ['validation.isNumber', { min: 1 }],
//   ],
//   disabled: [
//     [
//       'condition.testFieldValue',
//       'editorParams.terrain',
//       '==',
//       'FOLLOW',
//     ],
//   ],
// }];

// eslint-disable-next-line import/prefer-default-export
export const ADMIN = [
  // fieldName,
  // fieldDroneModel,
  // fieldWaypoints,
  // fieldTerrain,
  // getFilterWithCol(fieldElevationPrecision, 12),
  // getFilterWithCol(fieldElevationSimplificationFactor, 12),
  // getFilterWithCol(fieldAltitude, 12),
  // getFilterWithCol(fieldAddedHomeAltitude, 12),
  // getFilterWithCol(fieldSpeed, 12),
  // getFilterWithCol(fieldPitch, 12),
  // getFilterWithCol(fieldCaptureDistance, 24),
];
