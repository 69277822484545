import React from 'react';

import WaypointsEditor from '../../components/WaypointsEditor';

import getFilterWithCol from '../../helpers/getFilterWithCol';

import Types from '../../common/modules/types';

const editorTerrainIsFollow = [
  'condition.testFieldValue',
  'editorParams.terrain',
  '==',
  'FOLLOW',
];

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [
    ['validation.isRequired'],
  ],
}];

export const fieldDroneModel = ['FieldSelect', {
  name: 'editorParams.droneModel',
  label: null,
  prefix: 'Done Model:',
  choices: Types.MODELS.map(label => ({
    label,
    value: label,
  })),
  initialValue: 'Mavic 2 Enterprise Dual',
  hidden: [props => (
    !props.viewer
    || !props.viewer.email
    || !props.viewer.email.includes('@thehive.rs')
  )],
}];

export const fieldWaypoints = ['FieldReact', {
  name: 'waypoints',
  label: null,
  virtual: [() => false],
  renderContent: props => (
    <WaypointsEditor {...props} />
  ),
}];

export const fieldTerrain = ['FieldRadio', {
  name: 'editorParams.terrain',
  label: null,
  block: true,
  choices: [
    { label: 'Manual', value: 'NONE' },
    { label: 'With Elevation', value: 'ELEVATION' },
    { label: 'Auto Follow Terrain', value: 'FOLLOW' },
  ],
  initialValue: 'FOLLOW',
}];

export const fieldElevationPrecision = ['FieldText', {
  name: 'editorParams.elevationPrecision',
  // label: 'Elevation Precision',
  label: null,
  prefix: 'Precision:',
  type: 'number',
  addonAfter: 'm',
  initialValue: 10,
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 5, max: 50 }],
  ],
  disabled: [
    editorTerrainIsFollow,
  ],
}];

export const fieldElevationSimplificationFactor = ['FieldText', {
  name: 'editorParams.elevationSimplificationFactor',
  // label: 'Elevation Simplification Factor',
  label: null,
  prefix: 'Simplification:',
  type: 'number',
  initialValue: 500,
  addonAfter: 'm',
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 5, max: 2000 }],
  ],
  disabled: [
    editorTerrainIsFollow,
  ],
}];

export const fieldAltitude = ['FieldText', {
  name: 'altitude',
  label: null,
  prefix: 'Altitude:',
  addonAfter: 'm',
  type: 'number',
  initialValue: 100,
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 5, max: 480 }],
  ],
}];

export const fieldAddedHomeAltitude = ['FieldText', {
  name: 'editorParams.addedHomeAltitude',
  label: null,
  prefix: 'Home Altitude:',
  type: 'number',
  initialValue: 0,
  addonAfter: 'm',
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 0 }],
  ],
}];

export const fieldSpeed = ['FieldText', {
  name: 'speed',
  label: null,
  prefix: 'Speed:',
  addonAfter: 'm/s',
  type: 'number',
  initialValue: 7,
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 2, max: 15 }],
  ],
}];

export const fieldPitch = ['FieldText', {
  name: 'pitch',
  label: null,
  prefix: 'Pitch:',
  addonAfter: 'degrees',
  type: 'number',
  initialValue: 90,
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 0, max: 90 }],
  ],
  disabled: [() => true],
}];

export const fieldCaptureDistance = ['FieldText', {
  name: 'captureDistance',
  label: null,
  prefix: 'Capture Interval:',
  addonAfter: 'm',
  type: 'number',
  align: 'right',
  initialValue: 100,
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 1 }],
  ],
  disabled: [
    editorTerrainIsFollow,
  ],
}];

const [fieldForwardOverlap, fieldSideOverlap] = [
  ['forwardOverlap', 'Forward Overlap', 40],
  ['sideOverlap', 'Side Overlap', 20],
].map(([name, label, initialValue]) => ['FieldText', {
  name: `editorParams.${name}`,
  label: null,
  prefix: `${label}:`,
  addonAfter: '%',
  type: 'number',
  initialValue,
  align: 'right',
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', { min: 0, max: 100 }],
  ],
  disabled: [
    editorTerrainIsFollow,
  ],
}]);

export const ADMIN = [
  fieldName,
  fieldDroneModel,
  fieldWaypoints,
  fieldTerrain,
  getFilterWithCol(fieldElevationPrecision, 12),
  getFilterWithCol(fieldElevationSimplificationFactor, 12),
  getFilterWithCol(fieldAltitude, 12),
  getFilterWithCol(fieldAddedHomeAltitude, 12),
  getFilterWithCol(fieldSpeed, 12),
  getFilterWithCol(fieldPitch, 12),
  getFilterWithCol(fieldCaptureDistance, 24),
  getFilterWithCol(fieldForwardOverlap, 12),
  getFilterWithCol(fieldSideOverlap, 12),
];
