import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import {
  ADMIN as FILTERS_ADMIN,
} from './filters';

import {
  ADMIN as COLUMNS_ADMIN,
} from './columns';

import {
  ADMIN_ALL as FIELDS_ADMIN_ALL,
} from './fields';

const {
  viewerIsNotAdmin,
} = tests;

// const skipPassword = ({ data, viewer, activity }) => (
//   activity === 'create'
//   || (
//     activity === 'edit'
//     && getKey(data, '_id', 0) !== getKey(viewer, '_id', 1)
//   )
// );

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Users',
    icon: 'usergroup-add',
    path: '/users',
    hidden: [viewerIsNotAdmin],
    redirect: [['redirect.unauthorized']],
    archiveConfig: {
      title: 'Users',
      label: 'Users',
      icon: 'usergroup-add',
      createButtonPath: '/users/create',
      subtitleColumnPath: 'email',
      filters: FILTERS_ADMIN,
      columns: COLUMNS_ADMIN,
      skip: [viewerIsNotAdmin],
      hidden: [viewerIsNotAdmin],
      redirect: [
        ['redirect.unauthorized'],
      ],
    },
    singleConfig: {
      hidden: true,
      alias: '/users',
      headerTitle: 'Create User',
      backButtonPaths: ['/users'],
    },
    singleEditConfig: {
      getBackButtonPaths: props => (
          props.viewer && props.viewer.role === 'ADMIN'
        ? props.backButtonPaths
        : false
      ),
      actions: [
        ['ActionSave'],
        ['ActionDelete', {
          skip: [viewerIsNotAdmin],
        }],
        ['ActionWithRequest', {
          name: 'activate',
          title: 'Activate',
          skip: [
            ['condition.check', { path1: 'data.active', value2: true }],
          ],
          getRequestConfig: props => ({
            url: `users/actions/activate?id=${props.data._id}`,
            method: 'POST',
          }),
          handleSuccess: (data, props) => props.reload(),
        }],
        ['ActionWithRequest', {
          name: 'deactivate',
          title: 'Deactivate',
          ghost: true,
          skip: [
            ['condition.check', { path1: 'viewer._id', path2: 'data._id' }],
            viewerIsNotAdmin,
            ['condition.check', { path1: 'data.active', value2: false }],
          ],
          getRequestConfig: props => ({
            url: `users/actions/deactivate?id=${props.data._id}`,
            method: 'POST',
          }),
          handleSuccess: (data, props) => props.reload(),
        }],
        ['ActionWithRequest', {
          name: 'send-activation-email',
          title: 'Send Activation Email',
          ghost: true,
          skip: [
            ['condition.check', { path1: 'data.active', value2: true }],
          ],
          getRequestConfig: props => ({
            url: `users/actions/activate?id=${props.data._id}&email=true`,
            method: 'POST',
          }),
        }],
      ],
    },
    fields: FIELDS_ADMIN_ALL,
  }])
).pages;
