import Types from '../../common/modules/types';

import getFilterWithCol from '../../helpers/getFilterWithCol';

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    {
      OR: [
        { name: { REGEX: value, OPTIONS: 'i' } },
        { email: { REGEX: value, OPTIONS: 'i' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search name or email',
  }],
}];

export const filterRole = ['FilterField', {
  id: 'role',
  label: null,
  section: 'top',
  VALUES_MAP: Types.USER_ROLE_LIST.reduce(
    (agr, { id, label }) => {
      agr[id] = { role: { EQ: id } };
      return agr;
    },
    { any: null },
  ),
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'role',
    placeholder: 'Role',
    prepareValueForInput: value => (value ? value : 'any'),
    choices: [
      { label: 'Any Role', value: 'any' },
      ...Types.USER_ROLE_LIST.map(({ id: value, label }) => ({ value, label })),
    ],
  }],
}];

export const filterActive = ['FilterField', {
  id: 'status',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    active: { active: { EQ: true } },
    inactive: { active: { EQ: false } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : 'any'
  ),
  field: ['FieldSelect', {
    name: 'status',
    placeholder: 'Status',
    prepareValueForInput: value => (value ? value : 'any'),
    initialValue: 'any',
    choices: [{
      label: 'Any Status',
      value: 'any',
    }, {
      label: 'Active',
      value: 'active',
    }, {
      label: 'Inactive',
      value: 'inactive',
    }],
  }],
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const filterNotSignups = ['FilterHidden', {
  id: 'nonSignupUsers',
  section: 'bottom',
  build: builder => builder.add('where', { signup: { NE: true } }),
}];

export const ADMIN = [
  ...[
    [filterSearch, 8],
    [filterActive, 8],
    [filterRole, 8],
  ].map(([filter, col]) => getFilterWithCol(filter, col)),

  filterPagination,
  filterNotSignups,
];
