import Admin from 'hive-admin';
import OriginalActionSave from 'hive-admin/src/components/ActionSave';

export default class ActionSaveIfIsAdmin extends OriginalActionSave {
  static config = {
    ...OriginalActionSave.config,
    skip: [
      props => !props.viewer || props.viewer.role !== 'ADMIN',
    ],
  }
}

Admin.addToLibrary(
  'ActionSaveIfIsAdmin',
  config => ActionSaveIfIsAdmin.create(config),
);
